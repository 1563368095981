import React from 'react';
import './About.css'; // Import your site's CSS for styling

function AboutUs() {
  return (
    <div className="about-us-container">
      <h1 className="about-us-heading">About Us</h1>
      <div className="about-us-content">
        <div className="about-us-image">
          <img src="/images/IMG-2440.jpg" alt="Latte Art" />
        </div>
        <div className="about-us-text">
          <p>
            Welcome to the world of Latte Art! We are passionate about bringing the artistry of
            Italian coffee culture to you.
          </p>
          <p>
            At Latte Artistry, we believe that making coffee is more than just a routine; it's a
            form of self-expression. Our courses are designed to elevate your coffee-making skills
            to an art form.
          </p>
          <p>
            Our team of experienced baristas and coffee enthusiasts will guide you on a journey to
            master the delicate craft of latte art. Whether you're a beginner or a seasoned coffee
            lover, there's always something new to learn.
          </p>
          <p>
            Join us and discover the secrets of creating stunning latte art designs. Let your
            creativity flow as you transform a simple cup of coffee into a masterpiece.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
