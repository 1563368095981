import React, { useState } from 'react';
import './HamburgerMenu.css'; // Import the CSS file for styling

function HamburgerMenu({ mobileNavItems }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className={`hamburger-menu-container ${isOpen ? 'open' : ''}`}>
      <button className="hamburger-button" onClick={toggleMenu}>
        ☰
      </button>
      <div className="mobile-nav-overlay" onClick={closeMenu}></div>
      <div className="mobile-nav">
        <button className="close-button" onClick={closeMenu}>
          ✕
        </button>
        <ul className="mobile-nav-list">
          {mobileNavItems.map((item, index) => (
            <li key={index} className="mobile-nav-item">
              <a
                href="#"
                className="mobile-nav-link"
                onClick={closeMenu} // Close the menu when a link is clicked
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HamburgerMenu;
