import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-column">
        <div className="social-links">
          <a href="https://www.facebook.com/profile.php?id=61552659262264" className="social-link">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/love_tatini_coffee_official/" className="social-link">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.tiktok.com/@love_tatini_coffee?_t=8gajMS6HLQe&_r=1" className="social-link">
            <i className="fab fa-tiktok"></i> {/* Add TikTok icon */}
          </a>
          
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-text">
          &copy; 2023 Love Tatini Coffee. All Rights Reserved.
        </div>
      </div>
      <div className="footer-column">
        <nav className="footer-nav">
          <ul className="footer-nav-list">
            <li className="footer-nav-item">
              <a href="/" className="footer-nav-link">Home</a>
            </li>
            <li className="footer-nav-item">
              <a href="/courses" className="footer-nav-link">Courses</a>
            </li>
            <li className="footer-nav-item">
              <a href="/contact" className="footer-nav-link">Contact</a>
            </li>
            <li className="footer-nav-item">
              <a href="/About" className="footer-nav-link">About</a>
            </li>
            <li className="footer-nav-item">
              <a href="/gallery" className="footer-nav-link">Gallery</a>
            </li>
            {/* Add more links as needed */}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
