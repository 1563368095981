import React from 'react';
import './Home.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Footer from './Footer';

function Home() {
  const placeholders = [
    {
      id: 1,
      image: '/images/5CB187EB-F4D1-49AF-BD6A-9EB6638BD5B5.jpg',
      caption: 'Explore all our Courses',
      buttonText: 'Learn More',
      link: '/courses',
    },
    
    {
      id: 2,
      image: '/images/70E33958-0B0C-4578-AC57-261EAEA4D694.jpg',
      caption: 'Image Gallery',
      buttonText: 'Give a look',
      link: '/gallery',
    },
    {
      id: 3,
      image: '/images/7358C5AF-9C2E-4B2D-8F09-BFCB503ACC45.jpg',
      caption: 'Contacts',
      buttonText: 'Learn More',
      link: '/contact',
    },
    {
        id: 4,
        image: '/images/IMG-2440.jpg',
        caption: 'About Us',
        buttonText: 'Learn More',
        link: '/About',
      },
  ];

  return (
    <div className="home-container">
      <div className="home-content">
        <motion.div
          className="centered-image-container"
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 4 }}
        >
          <img
            src="/images/000-logo-Tatini-trasparente-.png"
            alt="Amazing Image"
            className="centered-image"
          />
        </motion.div>
        <h1 className="home-heading">Hey, Michael from Love Tatini Coffee! 🌟</h1>
        <p className="home-intro">
          Welcome to Love Tatini Coffee, we're all about sharing the love for latte art in Manchester! ☕❤️ I'm here to guide you on a latte art journey like no other. Join us, and together, we'll paint lattes with a whole lot of love and creativity! 🎨💕
        </p>
      </div>
      <div className="home-images">
        {placeholders.map((item) => (
          <div key={item.id} className="image-container">
            <Link to={item.link} className="image-link">
              <div className="image-wrapper">
                <img
                  src={item.image}
                  alt={item.caption}
                  className="home-image-img"
                />
              </div>
              <p className="image-caption">{item.caption}</p>
              <div className="image-button-container">
                <button className="home-button">{item.buttonText}</button>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
