import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import Courses from './Courses';
import Contact from './Contacts';
import Gallery from './Gallery';
import About from './About';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Include the Header component */}
        <Header />

        {/* Define your routes using Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/About" element={<About />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
