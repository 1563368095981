import React, { useState } from 'react';
import './Courses.css'; // Import the CSS file
import { Link } from 'react-router-dom';
import queryString from 'query-string';

function Courses() {
  const coursesData = [
    {
      name: 'Individual Coffee and Latte Art Course',
      description: `**Coffee Making Techniques:**
      - *Coffee Mix Exploration*: Dive into the world of coffee blends, discussing and explaining the various coffee mixtures according to the current needs of modern cafeterias.
      - *Grinding Perfection*: Explore the critical aspects of coffee grinding. Learn how to choose the ideal grind size and consider the factors that affect it, including bean variety and brewing method.
      - *Grinder Mastery*: Understand how to properly set and adjust the coffee grinder for optimal results. Achieve the precise coarseness or fineness required for your brewing method.
      - *Coffee Machine Wizardry*: Step into the realm of coffee machines. Discover the secrets of setting up the coffee machine just right to ensure consistent and delicious brews.
      - *Tasting Time*: Put your newfound knowledge to the test. Engage in a sensory journey, tasting different coffee brews to appreciate the impact of your choices and settings.

    **Latte Art Creation:**
      - *Milk Mastery*: Delve into the science and art of milk frothing. Learn the best techniques for heating and frothing milk to the perfect temperature and consistency.
      - *Pouring Perfection*: Discover the magic of pouring as you understand the importance of a steady hand. Learn the tricks of the trade to pour milk beautifully into your espresso.
      - *Artistic Expression*: Unleash your creativity as you explore the world of latte art. Discover various techniques and patterns, from hearts to rosettes and beyond. You will learn how to turn your coffee into a canvas and create stunning latte art that will wow your customers and friends alike.

    **Course Description:**
    In just 90 minutes, you will gain a comprehensive understanding of coffee making techniques and the fine art of latte creation. It is an immersive experience designed to equip you with the skills and knowledge needed to craft exceptional coffee and stunning latte art. Whether you are a budding barista or a coffee enthusiast, this course promises to take your coffee game to the next level.`,
    },
    {
      name: 'Group Coffee and Latte Art Course',
      description: 'Take your latte art skills to the next level with more intricate designs like rosettas and swans.',
    },
  ];

  const [selectedCourse, setSelectedCourse] = useState(null);

  const selectCourse = (courseIndex) => {
    setSelectedCourse(courseIndex);
  };

  const clearSelectedCourse = () => {
    setSelectedCourse(null);
  };

  return (
    <div className="courses-container">
      <h1 className="courses-heading">Courses</h1>
      <div className="course-list">
        {coursesData.map((course, index) => (
          <div className="course-card" key={index}>
            <h2>{course.name}</h2>
            <button onClick={() => selectCourse(index)}>More Info</button>
            <Link
              to={{
                pathname: '/contact',
                search: queryString.stringify({ courseName: course.name }),
              }}
              className="enquiry-button"
            >
              Send Enquiry
            </Link>
          </div>
        ))}
      </div>

      {selectedCourse !== null && (
        <div className="popup" onClick={clearSelectedCourse}>
          <div className="popup-content">
            <h1>Individual Latte Art Course</h1>
            <h2>Coffee Making Techniques:</h2>
            <ul>
              <li>Coffee Mix Exploration: Dive into the world of coffee blends, discussing and explaining the various coffee mixtures according to the current needs of modern cafeterias.</li>
              <li>Grinding Perfection: Explore the critical aspects of coffee grinding. Learn how to choose the ideal grind size and consider the factors that affect it, including bean variety and brewing method.</li>
              <li>Grinder Mastery: Understand how to properly set and adjust the coffee grinder for optimal results. Achieve the precise coarseness or fineness required for your brewing method.</li>
              <li>Coffee Machine Wizardry: Step into the realm of coffee machines. Discover the secrets of setting up the coffee machine just right to ensure consistent and delicious brews.</li>
              <li>Tasting Time: Put your newfound knowledge to the test. Engage in a sensory journey, tasting different coffee brews to appreciate the impact of your choices and settings.</li>
            </ul>
            <h2>Latte Art Creation:</h2>
            <ul>
              <li>Milk Mastery: Delve into the science and art of milk frothing. Learn the best techniques for heating and frothing milk to the perfect temperature and consistency.</li>
              <li>Pouring Perfection: Discover the magic of pouring as you understand the importance of a steady hand. Learn the tricks of the trade to pour milk beautifully into your espresso.</li>
              <li>Artistic Expression: Unleash your creativity as you explore the world of latte art. Discover various techniques and patterns, from hearts to rosettes and beyond. You will learn how to turn your coffee into a canvas and create stunning latte art that will wow your customers and friends alike.</li>
            </ul>
            <h2>Course Description:</h2>
            <p>In just 90 minutes, you will gain a comprehensive understanding of coffee making techniques and the fine art of latte creation. It is an immersive experience designed to equip you with the skills and knowledge needed to craft exceptional coffee and stunning latte art. Whether you are a budding barista or a coffee enthusiast, this course promises to take your coffee game to the next level.</p>

            <button onClick={clearSelectedCourse}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Courses;
