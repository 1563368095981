import React, { useState, useEffect } from 'react';
import './Contacts.css'; // Import the CSS file
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import queryString from 'query-string'; // Import queryString for parsing URL parameters

function Contacts() {
  const location = useLocation(); // Get the current location
  const { search } = location || {}; // Get the search query from the location or provide an empty object
  const { courseName } = queryString.parse(search || ''); // Parse the search query to get the course name

  // Define a list of course options
  const courseOptions = [
    
    'Individual Coffee and Latte Art Course',
    'Group Coffee and Latte Art Course',
  ];

  // State to store the selected course
  const [selectedCourse, setSelectedCourse] = useState(courseName || '');

  // Function to handle dropdown menu change
  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  // Define the email API URL
  const emailApiUrl = 'https://lovetatinicoffee-ea2662d912af.herokuapp.com/send-email';

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Gather form data (name, surname, email, phone, message)
    // Send a POST request to emailApiUrl with the form data
    // Handle the response (success or error)

    // Example code to send the email request
    try {
      const response = await fetch(emailApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: 'adbmanchester@gmail.com',
          subject: 'Contact Form Submission',
          text: `
            Name: Your Name
            Email: Your Email
            Phone: Your Phone
            Message: Your Message
            Course: ${selectedCourse}
          `,
        }),
      });

      if (response.ok) {
        // Email sent successfully, you can show a success message or redirect to a thank you page
        console.log('Email sent successfully');
      } else {
        // Handle email sending error, show an error message to the user
        console.error('Email sending failed');
      }
    } catch (error) {
      // Handle network or server error
      console.error('Network or server error:', error);
    }
  };

  return (
    <div className="contact-container">
      <h1 className="contact-heading">Contacts</h1>
      <p className="contact-intro">
        Get in touch about the course: {selectedCourse || 'No Course Selected'}
      </p>
      <div className="contact-form-container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="surname">Surname:</label>
            <input type="text" id="surname" name="surname" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address:</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input type="tel" id="phone" name="phone" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="5" // Increase the number of rows to make it bigger
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="course">Course:</label>
            <select
              id="course"
              name="course"
              value={selectedCourse}
              onChange={handleCourseChange}
            >
              <option value="">Select a Course</option>
              {courseOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contacts;
