import React, { useState } from 'react';
import './Gallery.css';

function Gallery() {
  // Array of image URLs (replace with your own images)
  const images = [
    // '/images/IMG_0842.jpeg',
    // '/images/IMG_1982.jpeg',
    // '/images/IMG_1984.jpeg',
    '/images/5CB187EB-F4D1-49AF-BD6A-9EB6638BD5B5.jpg',
    '/images/70E33958-0B0C-4578-AC57-261EAEA4D694.jpg',
    '/images/7358C5AF-9C2E-4B2D-8F09-BFCB503ACC45.jpg',
    '/images/BE9C4E77-314F-4E19-B642-AECCF9E6C432.jpg',
    '/images/IMG-2473.jpg',
    '/images/IMG-2474.jpg',
    '/images/IMG-2475.jpg',
    '/images/IMG-2476.jpg',
    '/images/IMG-2478.jpg',
    '/images/IMG-2479.jpg',

    // Add more image URLs here
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div className="thumbnail-grid">
        {images.map((image, index) => (
          <div key={index} className="thumbnail" onClick={() => handleImageClick(image)}>
            <img src={image} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="image-overlay" onClick={handleCloseImage}>
          <div className="image-popup">
            <img src={selectedImage} alt="Selected" />
            <button className="close-button" onClick={handleCloseImage}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gallery;
