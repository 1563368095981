import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import HamburgerMenu from './HamburgerMenu';
import logo from './logo192.png';

function Header() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="header-container">
        <Link to="/" className="logo-link">
        <img src={logo} alt="Logo" className="logo" />
      </Link>
      {isMobile ? (
        // Hamburger menu
        <HamburgerMenu mobileNavItems={[
          <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>,
          <Link to="/courses" className={`nav-link ${location.pathname === '/courses' ? 'active' : ''}`}>Courses</Link>,
          <Link to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Link>,
          <Link to="/gallery" className={`nav-link ${location.pathname === '/gallery' ? 'active' : ''}`}>Gallery</Link>,
          <Link to="/About" className={`nav-link ${location.pathname === '/About' ? 'active' : ''}`}>About us</Link>,

          // Add more mobile menu items as needed
        ]} />
      ) : (
        // Regular menu
        <nav className="top-nav">
          <ul className="nav-list">
            <li className="nav-item">
              <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/courses" className={`nav-link ${location.pathname === '/courses' ? 'active' : ''}`}>Courses</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</Link>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className={`nav-link ${location.pathname === '/gallery' ? 'active' : ''}`}>Gallery</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}>About us</Link>
            </li>
            {/* Add more regular menu items as needed */}
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Header;
